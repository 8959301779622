import React, { useEffect, useState, useRef } from "react"
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading"
import { useInView } from "react-intersection-observer"
import Scrollbar from "react-smooth-scrollbar"
import { DisableScrollPlugin } from "./scroll-plugins"
import SmoothScrollbar from "smooth-scrollbar"
import TransitionLink from "gatsby-plugin-transition-link"
import { TransitionState } from "gatsby-plugin-transition-link"
import { Spring } from "react-spring/renderprops"

import Player from "./player"
import * as clipboard from "clipboard-polyfill/text"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import "./project.css"
import UpRight from "../svgs/up-right.svg"
import Arrow from "../svgs/arrow-right.svg"
import LinkIcon from "../svgs/link.svg"
import Close from "../svgs/close.svg"
import Logo from "../svgs/logo-h.svg"
import LogoV from "../svgs/logo-v.svg"
import SEO from "../components/seo"

import { controlsPlayPause } from "../utils/vimeo-controls"
import { slug, getVimeoLink } from "../utils/url"

SmoothScrollbar.use(DisableScrollPlugin)

const serializerOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
    [INLINES.HYPERLINK]: (node, children) => {
      const destination = node && node.data && node.data.uri
      return (
        <a href={destination} className="inline-link">
          {children}
        </a>
      )
    },
  },
}

const Project = ({ pageContext }) => {
  let { project, siteUrl } = pageContext
  if (project.isParentProject && project.siblingProjects) {
    project = project.siblingProjects && project.siblingProjects[0]
  }
  const { projectTitle, brand } = project
  const [showVideo, setShowVideo] = useState(false)
  const supportsLazyLoading = useNativeLazyLoading()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  })

  const infoRef = useRef(null)
  const scrollContainer = useRef(null)

  const projectContent = project.projectDetails?.json
    ? documentToReactComponents(project.projectDetails.json, serializerOptions)
    : false

  const getReturnLink = (brand, title) => {
    return slug(`${brand}-${title}`)
  }

  const projectLink = slug(`${brand}-${projectTitle}`)
  const currentUrl = `${siteUrl}project/${projectLink}`

  function copyToClipboard() {
    clipboard.writeText(currentUrl).then(
      function () {
        console.debug("success!")
      },
      function () {
        console.debug("error!")
      }
    )
  }

  let isMobile = false
  if (typeof window !== "undefined") {
    if (navigator && navigator.userAgent) {
      isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
        navigator.userAgent
      )
        ? true
        : false
    }
  }
  useEffect(() => {
    const el = document.querySelector(".sibling-projects")
    ;["touchmove", "mousewheel", "wheel"].forEach(eventType => {
      if (el) el.addEventListener(eventType, e => e.stopPropagation())
    })

    const landingImage = document.querySelector(".landing-image")
    const mouseTakeover = document.querySelector(".mouse-takeover")
    if (mouseTakeover && window) {
      landingImage.addEventListener("mousemove", e => {
        mouseTakeover.style.left = `${e.pageX}px`
        mouseTakeover.style.top = `${e.pageY}px`
      })
    }
  })

  function showVideoEmbed(e) {
    setShowVideo(true)
    setTimeout(function () {
      document.querySelector(`#${projectLink}`).click()
    }, 200)
  }

  const handleMoreInfoClick = () => {
    const { scrollbar } = scrollContainer.current
    scrollbar.scrollTo(0, infoRef.current.offsetTop, 500)
  }

  const base = (
    <React.Fragment>
      {!showVideo && (
        <button
          onClick={showVideoEmbed}
          className="landing-image"
          style={{
            position: "relative",
            width: `100%`,
          }}
        >
          <img
            alt=""
            style={{ width: `100%` }}
            src={project.projectImage.fluid.src}
          />
          <span
            className="mouse-takeover"
            style={{
              position: `absolute`,
              zIndex: 10,
              top: `50%`,
              left: `50%`,
              height: `2em`,
              width: `4em`,
              marginLeft: `-2em`,
              marginTop: `-1em`,
            }}
          >
            PLAY
          </span>
        </button>
      )}
      <Spring
        to={{
          opacity: showVideo ? 1 : 0,
          position: showVideo ? `relative` : `absolute`,
        }}
        config={{
          duration: 1500,
        }}
      >
        {props => (
          <div style={props}>
            <Player
              controls={controlsPlayPause}
              options={{
                muted: false,
                autoplay: false,
                clickToPlay: true,
              }}
              id={projectLink}
              title={projectTitle}
              vimeoLink={getVimeoLink(project.vimeoLink)}
            />
          </div>
        )}
      </Spring>
    </React.Fragment>
  )

  const otherInfo = (
    <React.Fragment>
      <section className="project-bar">
        <div className="project-bar-inner">
          <div className="project-title">
            <span>{brand}</span>
          </div>
          <div className="project-moreinfo">
            <button onClick={handleMoreInfoClick}>
              More Info <Arrow className="arrow-down" />
            </button>
          </div>
        </div>
      </section>
      <section ref={infoRef} className="project-info">
        <div className="project-main">
          <span>{projectTitle}</span>
        </div>
        {!!projectContent && (
          <div className="project-description">{projectContent}</div>
        )}
        <div className="social-media-container">
          <h4>Share It</h4>
          <ul className="social-media">
            <li>
              <a
                className="link"
                href={`https://www.facebook.com/dialog/share&href=${currentUrl}`}
              >
                <span>Facebook</span> <UpRight className="arrow" />
              </a>
            </li>
            <li>
              <a
                className="link"
                href={`https://twitter.com/intent/tweet?url=${currentUrl}`}
              >
                <span>Twitter</span> <UpRight className="arrow" />
              </a>
            </li>
            <li>
              <button className="link" onClick={copyToClipboard}>
                <span>Copy to Clipboard</span>{" "}
                <LinkIcon className="link-icon" />
              </button>
            </li>
          </ul>
        </div>
        {project.siblingProjects && project.siblingProjects.length > 0 && (
          <div
            ref={!supportsLazyLoading ? ref : undefined}
            style={{ display: `flex`, flexWrap: `nowrap` }}
            className="sibling-projects"
          >
            {inView || supportsLazyLoading
              ? project.siblingProjects.map(sib => (
                  <TransitionLink
                    key={sib.projectTitle}
                    to={`../${getReturnLink(sib.brand, sib.projectTitle)}`}
                  >
                    <p>{sib.projectTitle}</p>
                    <img
                      loading="lazy"
                      className="sib-img"
                      alt=""
                      src={sib.projectImage.fluid.src}
                    />
                  </TransitionLink>
                ))
              : null}
          </div>
        )}
      </section>
    </React.Fragment>
  )

  return (
    <TransitionState>
      {({ transitionStatus, exit, entry }) => {
        const mount = ["entering", "entered"].includes(transitionStatus)
        const seconds = mount ? entry.length : exit.length
        return (
          <>
            <SEO title={projectTitle} />
            <Spring
              to={{
                opacity: mount ? 1 : 0,
              }}
              config={{
                duration: seconds * 2000,
                delay: 1000,
              }}
            >
              {/* Uses `fromProject` to communicate when we are navigating to the index from a project page so we can scroll to the project on the index that triggered the navigation to the project page */}
              {props => (
                <header className="header">
                  <TransitionLink
                    style={props}
                    className="home-link"
                    to={`/`}
                    state={{ fromProject: true }}
                    exit={{ length: 0 }}
                    entry={{ length: 0.5, delay: 0.01 }}
                  >
                    <Logo className="proj logo-here" />
                    <LogoV className="proj logo-here-v" />
                    <span className="sr-only">Go to home page</span>
                  </TransitionLink>
                  <TransitionLink
                    style={props}
                    to={`/`}
                    state={{
                      fromProject: true,
                      projectHash: getReturnLink(brand, projectTitle),
                    }}
                    className="page close-button"
                    exit={{ length: 0 }}
                    entry={{ length: 0.5, delay: 0.01 }}
                  >
                    <span>Close Project</span>
                    <Close className="icon-close" />
                  </TransitionLink>
                </header>
              )}
            </Spring>

            {!isMobile ? (
              <Scrollbar
                ref={scrollContainer}
                className="scroll-container"
                plugins={{
                  disableScroll: { direction: "x" },
                }}
              >
                <div className="project-container">{base}</div>
                {otherInfo}
              </Scrollbar>
            ) : (
              <>
                <Spring
                  to={{
                    opacity: mount ? 1 : 0,
                  }}
                  config={{
                    duration: seconds * 2000,
                  }}
                >
                  {props => (
                    <React.Fragment>
                      <div style={props} className="project-container">
                        {base}
                        {otherInfo}
                      </div>
                    </React.Fragment>
                  )}
                </Spring>
              </>
            )}
          </>
        )
      }}
    </TransitionState>
  )
}
export default Project
